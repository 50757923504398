export var BASE_URL = process.env.NODE_ENV == 'production' || window.location.hostname.includes('localhost') ? '' : '/admin';
export var V1_BASE_URL = 'https://checkbus.irontrain.co.kr';
export var V1_TEST_BASE_URL = 'http://49.236.145.134';
export var POPUP_BASE_URL = process.env.NODE_ENV == 'production' ? V1_BASE_URL : V1_TEST_BASE_URL;
export var V2_BASE_URL = process.env.NODE_ENV == 'production' ? 'https://api.checkbus.co.kr' : 'https://checktest.irontrain.co.kr';
// process.env.NODE_ENV == 'production' ? 'https://api.checkbus.co.kr' : 'http://10.0.25.11'; // ! 실서버(크론)으로 테스트 필요시
// cookie
export var MODAL_MANUAL_HIDE_COOKIE_TODAY = 'hideManualModalToday';
export var MODAL_MANUAL_HIDE_COOKIE = 'hideManualModal';
export var MODAL_ACCOUNT_POPUP_TODAY = 'hideAccountModalPopupToday';
export var TEACHER_TYPES = ['T', 'ETC'];
export var TEACHER_EXCEPT_PATHS = {
    student: [],
    setup: ['/receipt', '/check'],
    cash: ['', '/charge', '/charge-detail', '/use-detail'],
    account: ['', '/check', '/manage', '/create', '/statistics', '/calculate', '/sms', 'download'],
};
export var PAIED_EXCEPT_PATHS = {
    setup: ['/product'],
};
export var FREE_EXCEPT_PATHS = {
    cash: ['', '/charge', '/charge-detail', '/use-detail'],
};
export var NOT_REGISTRAION_ACCOUNT = {
    account: ['/manage', '/create', '/statistics', '/calculate', '/sms', 'download'],
};
export var NOT_ACCOUNT = {
    account: ['', '/check', '/manage', '/create', '/statistics', '/calculate', '/sms', 'download'],
};
export var NOT_USE_TAB = -1;
export var PAYMENT_CANCEL_PRERIOD = 90;
export var ANALYSIS_AREA_MIN = 1000000;
export var LINK_BILLING_METHOD_VIDEO = 'https://youtu.be/AJiXuMDPvkc';
export var LINK_PAY_METHOD_VIDEO = 'https://youtu.be/djHQjo7wbSo';
export var LINK_ALL_METHOD_PDF = 'https://kr.object.ncloudstorage.com/cdn-irontrain/checkbus/alim_complete_manual.pdf';
export var TableTypeKeys;
(function (TableTypeKeys) {
    TableTypeKeys[TableTypeKeys["account_calculate_academy"] = 0] = "account_calculate_academy";
    TableTypeKeys[TableTypeKeys["account_calculate_user"] = 1] = "account_calculate_user";
    TableTypeKeys[TableTypeKeys["account_check"] = 2] = "account_check";
    TableTypeKeys[TableTypeKeys["account_manage_list"] = 3] = "account_manage_list";
    TableTypeKeys[TableTypeKeys["account_manage_create"] = 4] = "account_manage_create";
    TableTypeKeys[TableTypeKeys["account_statistics_period"] = 5] = "account_statistics_period";
    TableTypeKeys[TableTypeKeys["account_statistics_daily"] = 6] = "account_statistics_daily";
    TableTypeKeys[TableTypeKeys["account_statistics_detail"] = 7] = "account_statistics_detail";
    TableTypeKeys[TableTypeKeys["attendance_covid_record"] = 8] = "attendance_covid_record";
    TableTypeKeys[TableTypeKeys["attendance_covid_unrecord"] = 9] = "attendance_covid_unrecord";
    TableTypeKeys[TableTypeKeys["attendance_manager"] = 10] = "attendance_manager";
    TableTypeKeys[TableTypeKeys["attendance_status_record"] = 11] = "attendance_status_record";
    TableTypeKeys[TableTypeKeys["attendance_status_unrecord"] = 12] = "attendance_status_unrecord";
    TableTypeKeys[TableTypeKeys["cash_charge"] = 13] = "cash_charge";
    TableTypeKeys[TableTypeKeys["cash_used"] = 14] = "cash_used";
    TableTypeKeys[TableTypeKeys["setup_check"] = 15] = "setup_check";
    TableTypeKeys[TableTypeKeys["sms_detail"] = 16] = "sms_detail";
    TableTypeKeys[TableTypeKeys["sms_send"] = 17] = "sms_send";
    TableTypeKeys[TableTypeKeys["sms_reservation"] = 18] = "sms_reservation";
    TableTypeKeys[TableTypeKeys["student_class"] = 19] = "student_class";
    TableTypeKeys[TableTypeKeys["student_list"] = 20] = "student_list";
    TableTypeKeys[TableTypeKeys["student_study"] = 21] = "student_study";
    TableTypeKeys[TableTypeKeys["student_teacher"] = 22] = "student_teacher";
    TableTypeKeys[TableTypeKeys["student_counsel"] = 23] = "student_counsel";
    TableTypeKeys[TableTypeKeys["magazine_birthday"] = 24] = "magazine_birthday";
})(TableTypeKeys || (TableTypeKeys = {}));
export var UserTablQeuryColumn = {
    calculate_list: 'account_calculate_academy',
    calculate_detail: 'account_calculate_user',
    account_check: 'account_check',
    account_manage_create: 'account_manage_create',
    account_manage_check: 'account_manage_list',
    account_statistics: 'account_statistics_period',
    account_statistics_daily: 'account_statistics_daily',
    account_analysis_month: 'account_statistics_detail',
    record_record: 'attendance_covid_record',
    record_unrecord: 'attendance_covid_unrecord',
    manager: 'attendance_manager',
    status_record: 'attendance_status_record',
    status_unrecord: 'attendance_status_unrecord',
    charge_detail: 'cash_charge',
    use_detail: 'cash_used',
    setup_check: 'setup_check',
    detail: 'sms_detail',
    send: 'sms_send',
    reservation: 'sms_reservation',
    class: 'student_class',
    student: 'student_list',
    study: 'student_study',
    teacher: 'student_teacher',
    counsel: 'student_counsel',
    birthday: 'magazine_birthday',
};
export var TableDefaultSortKey = {
    student_teacher: {
        sortKey: 'uclass',
        sortValue: 'asc',
    },
    student_class: { sortKey: 'rname', sortValue: 'asc' },
    student_list: { sortKey: 'rname', sortValue: 'asc' },
    student_study: { sortKey: 'reportdate', sortValue: 'desc' },
    student_counsel: { sortKey: 'postdate', sortValue: 'desc' },
    attendance_status_record: { sortKey: 'rname', sortValue: 'asc' },
    attendance_status_unrecord: { sortKey: 'rname', sortValue: 'asc' },
    attendance_covid_record: { sortKey: 'rname', sortValue: 'asc' },
    attendance_covid_unrecord: { sortKey: 'rname', sortValue: 'asc' },
    attendance_manager: { sortKey: 'start', sortValue: 'desc' },
    account_check: { sortKey: 'uname', sortValue: 'asc' },
    account_manage_list: { sortKey: '', sortValue: '' },
    account_manage_create: { sortKey: '', sortValue: '' },
    account_statistics_period: { sortKey: '', sortValue: '' },
    account_statistics_daily: { sortKey: 'amount', sortValue: 'desc' },
    account_statistics_detail: { sortKey: '', sortValue: '' },
    account_calculate_academy: { sortKey: '', sortValue: '' },
    account_calculate_user: { sortKey: 'complete_date_time', sortValue: 'asc' },
    sms_send: { sortKey: 'rname', sortValue: 'asc' },
    sms_detail: { sortKey: 'postdate', sortValue: 'desc' },
    sms_reservation: { sortKey: 'reserveddate', sortValue: 'asc' },
    setup_check: { sortKey: 'postdate', sortValue: 'desc' },
    cash_charge: { sortKey: 'chargeday', sortValue: 'desc' },
    cash_used: { sortKey: 'postdate', sortValue: 'desc' },
    magazine_birthday: { sortKey: 'ubirth', sortValue: 'desc' },
};
export var EventDates = ['2024-12-18 17:59:59', '2024-12-31 23:59:59'];
export var EventName = '24년 연말결산';
export var HyosungDisabledDates = [
    { startDate: '2024-12-13 23:30:00', endDate: '2024-12-14 07:00:00', endTimeStr: '오전 07시' },
    // { startDate: '2024-11-09 23:30:00', endDate: '2024-11-10 07:00:00', endTimeStr: '오전 07시' },
];
